.section {
  min-height: 60vh;
  overflow: auto;
}

.title {
  font-size: 2.5rem;
  margin-top: 5rem;
  text-align: center;
  text-transform: uppercase;
}

.scroll {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 90%;
  margin: 3rem auto;
}

.bubbleContainer {
  margin: 3rem;
}

.img {
  width: fit-content;
  height: fit-content;
}

.reviewFromContainer {
  margin: 1rem;
  display: flex;
}
