.section {
  height: 100vh;
  background-color: #000000;
  color: white;
}

.container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.title {
  font-size: 3.2rem;
  text-align: center;
  text-transform: capitalize;
  border-bottom: 2px solid #fff;
  padding-bottom: 10px;
  width: 530px;
}

.arrowContainer {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%, 50px);
}

@media (max-width: 650px) {
  .title {
    font-size: 2.2rem;
    width: 375px;
  }
}

@media (max-width: 450px) {
  .title {
    font-size: 1.8rem;
    width: 330px;
  }
}

@media (max-width: 370px) {
  .title {
    font-size: 1.5rem;
    width: 250px;
  }
}
