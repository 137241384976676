* {
  margin: 0;
  padding: 0;
  margin-block-start: 0;
  margin-block-end: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

textarea {
  font-family: inherit;
}

.active {
  background-color: #bab3b3;
  display: block;
  color: black;
  font-weight: bold;
}

.removeBg {
  background-color: transparent;
}

a {
  display: block;
}

a:hover {
  cursor: pointer;
  height: 100%;
  display: block;
}

@media (max-width: 919px) {
  a {
    display: block;
  }
}
