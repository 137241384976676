.section {
  min-height: 100vh;
  background: url(../../Assets/contact_us.jpg) no-repeat center center;
  background-size: cover;
}

.container {
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title {
  margin-top: 5rem;
  font-size: 2.5rem;
  font-weight: bold;
  text-transform: uppercase;
}

.subTitle {
  font-size: 1.5rem;
  font-weight: bold;
  width: 75%;
  margin: 4rem auto;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  margin: 0 auto 3rem auto;
}

.details {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.input {
  height: 20px;
  width: 300px;
  margin: 1rem;
  padding: 1rem;
  border-radius: 10px;
  outline: none;
  border: none;
}

.messageContainer {
  width: 100%;
}

.messageInput {
  height: 104px;
  width: 90%;
  margin-top: 2rem;
  border-radius: 10px;
  outline: none;
  border: none;
  padding: 1rem;
  box-sizing: border-box;
  resize: none;
}

.button {
  background-color: #fff;
  outline: none;
  border: 2px solid white;
  cursor: pointer;
  border-radius: 10px;
  font-weight: bold;
  margin-top: 2rem;
  font-size: 1.2rem;
  text-transform: uppercase;
  padding: 0.8rem 2rem;
}

.spinner {
  margin-top: 2rem;
  border-radius: 10px;
}

.button:hover {
  background-color: #000;
  color: white;
}

.button:disabled,
.button[disabled],
.button[disabled]:hover {
  cursor: default;
}

@media (max-width: 1023px) {
  .details {
    flex-direction: column;
  }
  .input {
    width: auto;
  }
  .button:hover {
    background-color: #fff;
    color: #000;
  }
}

@media (min-width: 500px) {
  .messageInput {
    width: 93%;
  }
}

@media (min-width: 700px) {
  .messageInput {
    width: 95%;
  }
}

@media (min-width: 1024px) {
  .messageInput {
    width: 97%;
  }
}
