.container {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
}

.email {
  height: 50px;
  width: 50px;
  background-color: red;
  border-radius: 50%;
  box-shadow: 0px 0px 8px 8px grey;
  transition: transform 0.5s;
}

.emailIcon {
  height: 35px;
  width: 35px;
  padding: 7.3px;
}

.whatsapp {
  border-radius: 50%;
  cursor: pointer;
  background-color: #25d366;
  outline: none;
  border: none;
  height: 50px;
  width: 50px;
  margin-top: 2rem;
  box-shadow: 0px 0px 8px 8px grey;
  transition: transform 0.5s;
}

.whatsappIcon {
  height: 50px;
  width: 50px;
}

.email:hover,
.whatsapp:hover {
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .email:hover,
  .whatsapp:hover {
    transform: none;
  }
}
